import React, { useRef } from "react";
import { useSlideInFromBottomAnimation } from "../animations/Animations";
import "./Features.css";

const featureItems = [
  {
    id: 1,
    imageSrc:
      "https://img.freepik.com/free-photo/still-life-wireless-cyberpunk-headphones_23-2151072175.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
    title: "Premium Audio Quality",
    description:
      "Experience the best in sound quality with our top-of-the-line wireless headphones.",
    buttonText: "Get your headset",
  },

  {
    id: 2,
    imageSrc:
      "https://img.freepik.com/free-photo/still-life-tech-device_23-2150722663.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
    title: "Cutting-Edge Mobile Technology",
    description:
      "Discover the latest in mobile technology with our state-of-the-art smartphones.",
    buttonText: "Explore now",
  },

  {
    id: 3,
    imageSrc:
      "https://img.freepik.com/free-photo/high-angle-controller-with-vr-glasses_23-2149829178.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=ais_hybrid",
    title: "Next-Gen Gaming Experience",
    description:
      "Immerse yourself in the next generation of gaming with our latest PlayStation console.",
    buttonText: "Discover now",
  },

  {
    id: 4,
    imageSrc:
      "https://img.freepik.com/free-photo/smartwatch-screen-digital-device_53876-96833.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=ais_hybrid",
    title: "Smartwatch Innovation",
    description:
      "Explore the latest in smartwatch technology with our cutting-edge devices.",
    buttonText: "Discover more",
  },
];

function Features() {
  const slideInFromBottomRef = useRef(null);
  useSlideInFromBottomAnimation(slideInFromBottomRef, 1, 0.5);
  return (
    <div className="features" ref={slideInFromBottomRef} id="about">
      {featureItems.map((item, index) => (
        <div
          key={item.id}
          className={`feature ${index % 2 === 0 ? "" : "feature--reverse"}`}
        >
          <div className="feature__image">
            <img src={item.imageSrc} alt={item.title} />
          </div>
          <div className="feature__text">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <button
              onClick={() => (window.location.href = "https://wa.link/ya68zj")}
            >
              {item.buttonText}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Features;
