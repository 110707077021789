import React, { useState } from "react";
import "./Header.css";
import { CloseIcon, MenuOpenIcon } from "../../assets/icons/Icons";
import { Logo } from "../../assets/images/Images";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthOpen, setIsAuthOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <header>
        <nav class="pc__nav">
          <div class="logo">
            <a href="/">
              <img src={Logo} alt="princobeatz global" />
            </a>
          </div>
          <ul class="nav-links">
            <li>
              <a href="#about">About</a>
            </li>

            <li>
              <a href="#about">Features</a>
            </li>

            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </header>

      <div class="mobile">
        <nav class="mobile__nav">
          <div class="logo">
            <a href="/">
              <img src={Logo} alt="princobeatz global" />
            </a>
          </div>

          <div class="hamburger-menu" onClick={toggleMenu}>
            {isMenuOpen ? <CloseIcon /> : <MenuOpenIcon />}
          </div>
        </nav>

        {/* Render mobile menu based on isMenuOpen state */}
        {isMenuOpen && (
          <div className="mobile-menu" id="mobileMenu">
            <ul className="mobile-links">
              <li>
                <a href="#about">About</a>
              </li>

              <li>
                <a href="#about">Features</a>
              </li>

              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
