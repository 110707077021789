import "./App.css";
import Address from "./components/address/Address";
import Features from "./components/features/Features";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Features />
      <Address />
    </div>
  );
}

export default App;
