import React, { useRef } from "react";
import { HeroImg } from "../../assets/images/Images";
import { useSlideInFromLeftAnimation } from "../animations/Animations";
import "./Hero.css";

function Hero() {
  const slideInFromLeftRef = useRef(null);
  useSlideInFromLeftAnimation(slideInFromLeftRef, 1, 0.5);

  return (
    <div className="hero" ref={slideInFromLeftRef}>
      <div className="hero__text">
        <h3>Princobeatz global</h3>

        <p>Home of phones and accessories</p>
      </div>

      <div className="hero__img">
        <img src={HeroImg} alt="" />
      </div>
    </div>
  );
}

export default Hero;
