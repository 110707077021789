import React from "react";
import "./Address.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Address = () => {
  return (
    <section className="address-section" id="contact">
      <h2>Visit Us</h2>
      <div className="address-container">
        <div className="address-item">
          <FaMapMarkerAlt className="icon" />
          <div className="address-details">
            <h3>Our Address</h3>
            <p>
              No.8 akanu ibiam road , umuahia opposite keystone Bank,( bank
              road)
            </p>
          </div>
        </div>
        <div className="address-item">
          <FaPhoneAlt className="icon" />
          <div className="address-details">
            <h3>Call Us</h3>
            <p>Phone: +234 702 643 4109</p>
          </div>
        </div>
        <div className="address-item">
          <FaEnvelope className="icon" />
          <div className="address-details">
            <h3>Email Us</h3>
            <p>Email: Princobeatz@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Address;
